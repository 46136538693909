import { Stack, Typography } from "@mui/material";
import CustomFormikField from "../../../formik/CustomFormikField";
import { styled } from "@mui/material/styles";
// icons
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from "react-i18next";
import CountryCodeSelect from "../../../formik/CountryCodeSelect";
import PasswordInput from "../../../formik/PasswordInput";
import SubmitButton from "../../../formik/SubmitButton";
import CheckboxFormik from "../../../formik/CheckboxFormik";
import { Link } from "react-router-dom";
const LinkStyled = styled(Link)(({ theme }) => ({
    textDecoration: "none",
    color: theme.palette.primary.main,
    marginLeft: '4px',
    '&:hover': {
        textDecoration: 'underline',
    }
}));
const Label = ({ t }) => {
    return (
        <Typography variant="body2" color="white">
            {t("signUpinput.agree")}
            <LinkStyled to="/terms-of-service" sx={{ fontSize: ['12px', '14px'] }}>
                {t("signUpinput.terms")}
            </LinkStyled>
            <LinkStyled to="/privacy-policy" sx={{ fontSize: ['12px', '14px'] }}>
                {t("signUpinput.privacy")}
            </LinkStyled>
        </Typography>
    )
}
export const SignUpFiled = ({ signUpmethod, loading }) => {
    const { t } = useTranslation("signup");
    return (
        <Stack spacing={2}>
            <CustomFormikField
                name="username"
                placeholder={t("signUpinput.username")}
                icon={<PermContactCalendarIcon sx={{ fontSize: "15px" }} />}
                size="small"
            />
            {signUpmethod === "phone" ? (
                <div style={{ display: 'flex' }}>
                    <CountryCodeSelect
                        name="countryCode"
                        type="tel"
                        placeholder={t("signUpinput.phone")}
                        size="small"
                    />
                    <CustomFormikField
                        name="identifier"
                        type="tel"
                        placeholder={t("signUpinput.phone")}
                        size="small"
                        sx={{ flex: 1, borderRadius: "0px" }}
                    />
                </div>
            ) : (
                <CustomFormikField
                    name="identifier"
                    type="email"
                    placeholder={t("signUpinput.email")}
                    icon={<MailOutlineIcon sx={{ fontSize: "15px" }} />}
                    size="small"
                />
            )}
            <PasswordInput name="password" placeholder={t("signUpinput.password")} size="small" />
            <CheckboxFormik name="terms" Label={<Label t={t} />} />
            <SubmitButton disabled={loading}>
                {t("create-account")} {" "}
            </SubmitButton>
        </Stack>
    )
}