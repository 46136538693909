import { TableCell, TableRow, Button, Typography, } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from "@mui/material/styles";
import { getStatusColor } from "./helper/helperFun";
import { formatDate } from "../../helper/formatDate";
import { useTransactionActions } from "./hooks/transactionActions";
import { useTranslation } from "react-i18next";
export const TransactionRow = ({ transaction, userId }) => {
  const { t } = useTranslation("history");
  const { handleCancelTransaction, handleNavigateToAddReview, handleConfirmAsPaid } = useTransactionActions();
  const theme = useTheme();
  const linkStyle = { textDecoration: "none", color: theme.palette.primary.main };
  const isOwner = userId === transaction?.ownerID?._id;
  const canCancel = transaction?.status === "Pending" && !isOwner;
  const canReview = !(isOwner || transaction?.productID?.isDeleted);
  return (
    <TableRow>
      <TableCell align="center" sx={{ color: "primary.main" }}>
        <Link to={`/product-detail/${transaction?.productID?._id}`} style={linkStyle}>
          {transaction?.productID?.name}
        </Link>
      </TableCell>
      <TableCell align="center" sx={{ color: "secondary.main" }}>
        {formatDate(transaction?.startDate)}
      </TableCell>
      <TableCell align="center" sx={{ color: "secondary.main" }}>
        {formatDate(transaction?.endDate)}
      </TableCell>
      <TableCell align="center" sx={{ color: "secondary.main" }}>
        {transaction?.userID?._id === userId ? "You" : transaction?.userID?.displayName}
      </TableCell>
      <TableCell align="center">
        {isOwner ? "You" : transaction?.ownerID?.displayName}
      </TableCell>
      <TableCell align="center" sx={{ color: "secondary.main" }}>
        <Button
          variant="text"
          color="primary"
          sx={{ fontSize: ["10px", "15px"] }}
          disabled={!canCancel}
          onClick={() => handleCancelTransaction(transaction?._id)}
        >
          {t("table.Cancel")}
        </Button>
      </TableCell>
      <TableCell align="center" sx={{ color: "secondary.main" }}>
        {!transaction?.isPaid  ? (
          <CheckCircleIcon
            sx={{
              fontSize: "25px",
              color: "green",
              cursor: isOwner && transaction?.status === "Pending Payment" ? "pointer" : "default",
              opacity: isOwner && transaction?.status === "Pending Payment" ? 1 : 0.5
            }}
            onClick={() => isOwner && transaction?.status === "Pending Payment" && handleConfirmAsPaid(transaction?._id)}
          />
        ) : (
          <Typography variant="caption" sx={{ fontSize: "17px", color: "green" }}>
            {t("table.paid")}
          </Typography>
        )}
      </TableCell>
      <TableCell align="center">
        <Button
          variant="text"
          color="primary"
          sx={{ fontSize: ["10px"] }}
          onClick={() => handleNavigateToAddReview(transaction?.productID?._id)}
          disabled={!canReview}
        >
          <AddIcon sx={{ fontSize: "20px" }} />
        </Button>
      </TableCell>
      <TableCell align="center" sx={{ color: getStatusColor(transaction?.status) }}>
        {transaction?.status}
      </TableCell>
    </TableRow>
  );
};