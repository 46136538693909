import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import { UserAvatar } from '../components/general/UserAvatar';
import { FormAcountInfo } from '../components/Settings/acount-info/FormAcountInfo';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../redux/slices/AuthUser';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NotesForUser from '../components/Settings/acount-info/NotesForUser';
export const AcountInfo = () => {
  const { t } = useTranslation("settings");
  // get user from redux 
  const authUser = useSelector(getUser);
  const navigate = useNavigate();
  // handle back button
  const handleBack = () => {
    navigate("/settings");
  }
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Stack spacing={2} direction="row" alignItems="center" mb={5}>
          <Box  >
            <ArrowBackIcon sx={{ color: "primary.main", fontSize: "30px", cursor: "pointer" }} onClick={handleBack} />
          </Box>
          <Typography variant="h4" align="center" sx={{ fontWeight: "bold", color: "primary.main", pb: 5, flexGrow: 1 }}>
            {t("acount-info.title")}
          </Typography>

        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <UserAvatar user={authUser?.user} displayInProfile={true} />
            <NotesForUser />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <FormAcountInfo userInfo={authUser?.user} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

