import { Box, Typography } from '@mui/material';

const KeyAgreementItem = ({ title, content }) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="body1">
        {content}
      </Typography>
    </Box>
  );
};

export default KeyAgreementItem;
