import { Box, Typography, Link } from '@mui/material';

const NotesForUser = () => {
  return (
    <Box mt={4} p={4} bgcolor="grey.50" borderRadius={2} border={1} borderColor="grey.200">
      <Typography variant="body2" color="textSecondary">
        You must send an email to{' '}
        <Link 
          href="mailto:info@directrent.com" 
          color="primary"
          underline="hover"
        >
          info@directrent.com
        </Link>
        {' '}to reach the system administrator if you wish to change your email address or mobile number.
      </Typography>
    </Box>
  );
}

export default NotesForUser;