import { AppBar, Box, Button, Container, Divider, Stack, Toolbar, Typography } from '@mui/material'
import Logo from "../../../../assets/images/logo.svg";
import menu from "../../../../assets/images/menu.svg";
import { Link } from 'react-router-dom'
import { Notifications } from '../../../notifications/Notifications';
import { Main } from '../Main';
import { handleRenderLink } from '../HandleLinks';
import { AuthLinks } from './authLinks';
import { UserAvatar } from './UserAvatar';
import { useTranslation } from 'react-i18next';
export const AppBars = ({ user, handleOpenStack, handleOpenDrawer, navbarRef }) => {
    const { t } = useTranslation("header");
    return (
        <AppBar position="relative" sx={{ backgroundColor: "background.default", zIndex: 1100, boxShadow: "none" }} ref={navbarRef}>
            <Main />
            <Divider />
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        component={Link}
                        to="/"
                        sx={{
                            mr: 2,
                            flexGrow: { xs: 1, sm: 0 },
                            display: "flex",
                            alignItems: "center",
                            width: ["80px", "150px"],
                            height: ["50px", "80px"],
                            my: 1,
                        }}
                    >
                        <img src={Logo} alt="DirectRent" loading='lazy' style={{ width: "100%", height: "100%" }} />
                    </Typography>

                    <Box
                        sx={{
                            display: ["none", "none", "none", "flex"],
                            justifyContent: "center",
                            alignItems: "center",
                            flexGrow: 1,
                        }}
                    >
                        {
                            handleRenderLink(user).map((link, index) => (
                                link.renderd ? (
                                    <Button
                                        key={index}
                                        sx={{ color: "secondary.main", display: "block", textTransform: "capitalize", fontSize: "16px", fontWeight: "bold" }}
                                        component={Link}
                                        to={link.to}
                                    >
                                        {t(link.name)}
                                    </Button>
                                ) : null
                            ))
                        }

                    </Box>

                    <Stack sx={{ dispaly: "flex", alignItems: "center", gap: "10px", justifyContent: "end", flexDirection: "row", flexGrow: [1, 1, 1, 0] }}>

                        {
                            user ? (
                                <>
                                    <Notifications />
                                    <UserAvatar user={user} handleOpenStack={handleOpenStack} />

                                </>
                            ) : (
                                <AuthLinks />
                            )
                        }
                    </Stack>

                    {/*menu icon for mobile*/}
                    <Box sx={{ display: ["block", "block", "block", "none"],ml:2 }}>
                        <img
                            src={menu}
                            alt="menu"
                            style={{ width: "25px", height: "25px", cursor: "pointer" }}
                            onClick={handleOpenDrawer}
                        />
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    )
}
