import { Typography, Box, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PrivacyPolicyContent from '../components/termsOfService/PrivacyPolicyContent';
export const PrivacyPolicy = () => {
    const { t } = useTranslation("privacy-policy");
    return (
        <Box >

            <Typography
                variant="h4"
                align="center"
                sx={{ color: 'primary.main', fontFamily: 'Poppins-Bold', fontSize: ["18px", "35px"], my: 8 }}
            >
                {t('title')}
            </Typography>
            <Box sx={{ backgroundColor: "text.main" }}>
                <Container>
                    <PrivacyPolicyContent />
                </Container>
            </Box>

        </Box>
    );
};
