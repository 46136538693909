export const SECTIONS = {
  INTRODUCTION: 'introduction',
  KEY_AGREEMENTS: 'key_agreements',
  SERVICE: 'service',
  USE_OF_SERVICE: 'use_of_service',
  USER_CONTENT: 'user_content',
  USER_CONTENT2: 'user_content2',
  OWNERSHIP_LICENSE: 'ownership_and_license',
  REPRESENTATIONS_WARRANTIES: 'representations_and_warranties',
  DISCLAIMER_LIABILITY: 'disclaimer_and_liability',
  CONTENT_REMOVAL: 'content_removal',
  PROPRIETARY_RIGHTS: 'proprietary_rights',
  IDEAS_FEEDBACK: 'ideas_and_feedback',
  TERMS_OWNERS: 'terms_for_owners',
  TERMS_RENTERS: 'terms_for_renters',
  INTERACTIONS_AND_DISPUTES: 'interactions_and_disputes',
  RATINGS_AND_REVIEWS: 'ratings_and_reviews',
  FEES_AND_PAYMENTS: 'fees_and_payments',
  TEXT_MESSAGING: 'text_messaging',
  SECURITY: 'security',
  NO_WARRANTY: 'no_warranty',
  LIMITATION_OF_LIABILITY: 'limitation_of_liability',
  GOVERNING_LAW: 'governing_law',
  MOBILE_APPLICATIONS: 'mobile_applications',
  GENERAL: 'general',
  THIRD_PARTY_LINKS: 'third_party_links',
  INDEMNITY: 'indemnity',
}; 