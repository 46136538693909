import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SECTIONS } from './constants/sections';
import { getTranslatedArray, getTranslatedObject } from './utils/translationHelpers';
import Section from './components/Section';
import KeyAgreementItem from './components/KeyAgreementItem';
import TermsSection from './components/TermsSection';
import ListSection from './components/ListSection';

const TermsContent = () => {
  const { t } = useTranslation("terms-of-service");

  const renderSection = (sectionKey, options = {}) => {
    const {
      showTitle = true,
      id,
      hasItems,
      hasAdditionalContent,
      hasSections,
      isKeyAgreement,
      isWarranty
    } = options;

    const section = (
      <Section
        title={showTitle ? t(`${sectionKey}.title`) : null}
        content={!hasSections ? t(`${sectionKey}.content`) : null}
        id={id}
      >
        {hasSections && Object.entries(getTranslatedObject(t, `${sectionKey}.sections`)).map(([key, section]) => (
          <TermsSection key={key} section={section} id={key} />
        ))}

        {hasItems && <ListSection items={getTranslatedArray(t, `${sectionKey}.items`)} />}

        {hasAdditionalContent && (
          <Typography variant="body1" sx={{ mt: 2, whiteSpace: 'pre-line' }}>
            {t(`${sectionKey}.additional_content`)}
          </Typography>
        )}

        {isKeyAgreement && getTranslatedArray(t, `${sectionKey}.items`).map((item, index) => (
          <KeyAgreementItem
            key={index}
            title={item.title}
            content={item.content}
          />
        ))}

        {isWarranty && <ListSection items={getTranslatedArray(t, `${sectionKey}.warranties`)} />}
      </Section>
    );

    return section;
  };

  const renderUserContent2 = () => {
    const userContent = getTranslatedObject(t, SECTIONS.USER_CONTENT2);
    if (!userContent?.title) return null;

    return (
      <Section title={userContent.title}>
        {userContent.content && (
          <Typography variant="body1" sx={{ mb: 2 }}>{userContent.content}</Typography>
        )}
        {userContent.items && <ListSection items={userContent.items} />}
        {userContent.contact_info && (
          <Typography variant="body1" sx={{ mt: 3, whiteSpace: 'pre-line' }}>
            {userContent.contact_info}
          </Typography>
        )}
        {userContent.important_notice && (
          <Typography
            variant="body1"
            sx={{
              mt: 3,
              whiteSpace: 'pre-line',
              fontWeight: 'medium',
              bgcolor: 'grey.100',
              p: 2,
              borderRadius: 1
            }}
          >
            {userContent.important_notice}
          </Typography>
        )}
      </Section>
    );
  };

  return (
    <Box sx={{ py: 8, px: 2, maxWidth: '1200px', margin: '0 auto' }}>
      <Typography variant="h4" sx={{ mb: 4, textAlign: 'center', fontSize: ["16px", "24px"] }}>
        {t('title')}
      </Typography>

      {renderSection(SECTIONS.INTRODUCTION, { id: 'introduction' })}
      {renderSection(SECTIONS.KEY_AGREEMENTS, { isKeyAgreement: true })}
      {renderSection(SECTIONS.SERVICE, { id: 'service' })}
      {renderSection(SECTIONS.USE_OF_SERVICE, { hasSections: true })}
      {renderSection(SECTIONS.USER_CONTENT, { id: 'user-content' })}
      {renderSection(SECTIONS.OWNERSHIP_LICENSE, { id: 'ownership-license' })}
      {renderSection(SECTIONS.REPRESENTATIONS_WARRANTIES, { isWarranty: true })}
      {renderSection(SECTIONS.DISCLAIMER_LIABILITY)}
      {renderSection(SECTIONS.CONTENT_REMOVAL)}
      {renderSection(SECTIONS.PROPRIETARY_RIGHTS)}
      {renderSection(SECTIONS.IDEAS_FEEDBACK)}
      {renderSection(SECTIONS.TERMS_OWNERS, { hasSections: true })}
      {renderSection(SECTIONS.TERMS_RENTERS, { hasSections: true })}
      {renderSection(SECTIONS.INTERACTIONS_AND_DISPUTES)}
      {renderSection(SECTIONS.RATINGS_AND_REVIEWS)}
      {renderSection(SECTIONS.FEES_AND_PAYMENTS, { hasSections: true })}
      {renderSection(SECTIONS.TEXT_MESSAGING)}
      {renderSection(SECTIONS.SECURITY)}
      {renderUserContent2()}
      {renderSection(SECTIONS.THIRD_PARTY_LINKS)}
      {renderSection(SECTIONS.INDEMNITY, { hasItems: true })}
      {renderSection(SECTIONS.NO_WARRANTY)}
      {renderSection(SECTIONS.LIMITATION_OF_LIABILITY, { hasItems: true, hasAdditionalContent: true })}
      {renderSection(SECTIONS.GOVERNING_LAW, { hasSections: true, id: "governing_law" })}
      {renderSection(SECTIONS.MOBILE_APPLICATIONS, { hasSections: true })}
      {renderSection(SECTIONS.GENERAL, { hasSections: true })}
    </Box>
  );
};

export default TermsContent;