import axiosInstance from "../../../axios/axiosInstance";
import { useShowAlert } from "../../../hooks/useShowAlert";
import useSetNotificationAsRead from "./SetNotificationAsReaded";
export const useConfirmPayment = () => {
    const { showAlert } = useShowAlert();
    const setNotificationAsRead = useSetNotificationAsRead();
    const handleConfirmAsPaid = (transactionId,notificationId) => {
        axiosInstance.put(`/transactions/${transactionId}`, { isPaid: true, status: "Ongoing" })
          .then((res) => {
            showAlert("success", res.data.message);
            setNotificationAsRead(notificationId); // set notification as read
          });
      };

    return handleConfirmAsPaid;
}
