import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Section from './components/Section'

const PrivacyPolicyContent = () => {
  const { t } = useTranslation("privacy-policy")

  const renderSection = (sectionKey, options = {}) => {
    const { showTitle = true } = options

    return (
      <Section
        title={showTitle ? t(`${sectionKey}.title`) : null}
        content={t(`${sectionKey}.content`, { defaultValue: '' })}
      >
        {options.sections && Object.keys(t(`${sectionKey}.sections`, { returnObjects: true })).map((key) => (
          <Box key={key} sx={{ py: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
              {t(`${sectionKey}.sections.${key}.title`)}
            </Typography>
            <Typography variant="body1">
              {t(`${sectionKey}.sections.${key}.content`)}
            </Typography>
          </Box>
        ))}

      </Section>
    )
  }

  return (
    <Box sx={{ py: 8, px: 2, maxWidth: '1200px', margin: '0 auto' }}>
      {/* Title */}
      <Typography variant="h4" sx={{ mb: 4, textAlign: 'center', fontSize: ["16px", "24px"] }}>
        {t('title')}
      </Typography>

      {/* Introduction */}
      {renderSection('introduction')}

      {/* Information Collection */}
      {renderSection('information_collection', { sections: true })}

      {/* Information Use */}
      {renderSection('information_use')}

      {/* Information Sharing */}
      {renderSection('information_sharing', { sections: true })}

      {/* Data Security */}
      {renderSection('data_security')}

      {/* Data Retention */}
      {renderSection('data_retention')}

      {/* Your Rights */}
      {renderSection('your_rights', { sections: true, note: true })}

      {/* Third Party */}
      {renderSection('third_party')}

      {/* Children's Privacy */}
      {renderSection('children')}

      {/* International Users */}
      {renderSection('international')}

      {/* Changes */}
      {renderSection('changes')}

      {/* Contact */}
      {renderSection('contact')}
    </Box>
  )
}

export default PrivacyPolicyContent