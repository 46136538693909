import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useSignUpValidation = () => {
    const { t } = useTranslation("signup");
    // signup validation schema
    const signupValidationSchema = yup.object({
        username: yup
            .string()
           /*  .required(t("formErrors.username.required")) */
            .min(3, t("formErrors.username.minLength"))
            .max(30, t("formErrors.username.maxLength"))
            .matches(
                /^[a-zA-Z0-9]+$/,
                t("formErrors.username.invalidChars")
            ),
        identifier: yup
            .string()
            .required(t("formErrors.identifier.required"))
            .test("email-or-phone", t("formErrors.identifier.invalid"), function (value) {
                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

                // Phone number validation for specific countries
                const phonePatterns = {
                    US: /^\d{10}$/, // USA: 10 digits without +1
                    ES: /^\d{9}$/, // Spain: 9 digits without +34
                    EG: /^\d{10}$/, // Egypt: 10 digits without +20
                };

                if (emailRegex.test(value)) {
                    return true; // Valid email
                }

                // Check if the phone number matches any country pattern
                for (const pattern of Object.values(phonePatterns)) {
                    if (pattern.test(value)) {
                        return true; // Valid phone number for one of the specified countries
                    }
                }

                return false; // Neither a valid email nor a valid phone number
            }),
        password: yup
            .string()
            .required(t("formErrors.password.required"))
            .min(8, t("formErrors.password.minLength"))
            .matches(
                /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/,
                t("formErrors.password.invalidFormat")
            ),
        countryCode: yup.string().when("identifier", {
            is: (identifier) => /^\d+$/.test(identifier),
            then: (schema) =>
                schema.required(t("formErrors.countryCode.required")),
        }),
        terms: yup
            .boolean().oneOf([true], t("formErrors.terms.required")),
    });

    return { signupValidationSchema }
}


