import { UserTransactionsSearch } from "../../../context"; 
import axiosInstance from "../../../axios/axiosInstance";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useShowAlert } from "../../../hooks/useShowAlert";

export const  useTransactionActions = () => {
    const { showAlert } = useShowAlert();
    const navigate = useNavigate();
    const { handleupdateTransactions } = useContext(UserTransactionsSearch);
   // handle cancel transaction
  const handleCancelTransaction = (id) => {
    axiosInstance.put(`/transactions/cancel/${id}`)
      .then((res) => {
        showAlert("success", res.data.message);
        handleupdateTransactions(); // update transactions
      })
      .catch((err) => {
        if (err) {
          showAlert("error", err.response.data.error);
        }
      });
  };
// handle navigate to add review
  const handleNavigateToAddReview = (id) => {
    navigate(`/add-review/${id}`);
  };
  // handle confirm as paid
  const handleConfirmAsPaid = (id) => {
    axiosInstance.put(`/transactions/${id}`, { isPaid: true, status: "Ongoing" })
      .then((res) => {
        showAlert("success", res.data.message);
        handleupdateTransactions(); // update transactions
      });
  };

  return { handleCancelTransaction, handleNavigateToAddReview, handleConfirmAsPaid };
}