import { List, ListItem, ListItemText } from '@mui/material';

const ListSection = ({ items = [] }) => {
  if (!items || items.length === 0) return null;
  
  return (
    <List sx={{ pl: 2 }}>
      {items.map((item, index) => (
        <ListItem key={index} sx={{ display: 'list-item', listStyleType: 'disc', py: 0.5 }}>
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  );
};

export default ListSection;
