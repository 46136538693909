import { useField } from 'formik';
import { Box, Checkbox, Stack, Typography } from '@mui/material';
const CheckboxFormik = ({ name, Label, ...props }) => {
    const [field, meta] = useField(name);
    return (
        <Box >
            <Stack direction="row" spacing={1} alignItems="start">
                <Checkbox
                    {...field}
                    {...props}
                    sx={{
                        p: 0
                    }}

                />
                {Label}

            </Stack>
            {meta.error && meta.touched && (
                <Typography
                    color="error"
                    variant="caption"

                >
                    {meta.error}
                </Typography>
            )}
        </Box>
    );
};

export default CheckboxFormik;
