import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import VerifiedIcon from "@mui/icons-material/Verified";
import AvatarUI from "./AvatarUI";
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../../context';
import { useConfirmPayment } from './hooks/confirmPayment';
const NotificationItem = ({ notification, type, actions }) => {
    const handleConfirmAsPaid = useConfirmPayment(); // handle confirm as paid
    const { handleCloseDrawer } = useContext(NotificationContext);
    const { t } = useTranslation("notifications");
    const theme = useTheme();
    const navigate = useNavigate();
    const linkStyle = useMemo(() => ({ fontSize: "12px", marginTop: "6px", color: theme.palette.secondary.main, textDecoration: "none", fontWeight: "400" }), [theme.palette.secondary.main]);
    const handleNavigation = (path) => {
        navigate(path);
        handleCloseDrawer();// Close the drawer after navigation
    };

    const setAsRead = () => {
        actions.setNotificationAsRead(notification._id);
    };

    const renderContent = () => {
        switch (type) {
            case 'transaction approved':
                return (
                    <>
                        <AvatarUI sender={notification?.sender} />
                        <Link to="/transactions" style={linkStyle}>
                            {notification.title}
                        </Link>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2, fontSize: "12px", textDecoration: "none", "&:focus": { outline: "none" } }}
                            component={Link}
                            to={`/payment/${notification?.objectId}`}
                        >
                            {t("approve.completTransation")}
                        </Button>
                    </>
                );
            case 'comment':
                return (
                    <>
                        <AvatarUI sender={notification?.sender} />
                        <Typography sx={{ fontSize: "14px", color: theme.palette.text.primary, m2: 3 }}>
                            {notification.title}
                        </Typography>
                        <Button onClick={() => handleNavigation(`/product-detail/${notification?.objectId}`)} sx={{ mt: 1 }} variant="contained" color="primary">
                            {t("comment.viewDetails")}
                        </Button>
                    </>
                );
            case 'transaction paid':
                return (
                    <>
                        <AvatarUI sender={notification?.sender} />
                        <Stack spacing={2} sx={{ alignItems: "center", backgroundColor: "transparent !important" }}>
                            <Link to="/transactions" style={linkStyle}>
                                {notification?.title}
                            </Link>
                            {
                                notification?.transactionId?.isPaid === false && <Button
                                    variant="contained"
                                    sx={{ backgroundColor: "primary.main", color: "white", fontSize: "12px" }}
                                    onClick={() => handleConfirmAsPaid(notification?.objectId, notification?._id)}
                                >
                                    {t("ongoing.confirmAsPaid")}
                                </Button>
                            }
                        </Stack>
                    </>
                );
            case 'transaction created':
                return (
                    <>
                        <AvatarUI sender={notification.sender} />
                        <Box direction="row" sx={{ alignItems: "center", backgroundColor: "transparent !important" }}>
                            <Link to="/transactions" style={linkStyle}>
                                {notification?.title}
                            </Link>
                        </Box>
                        <Stack direction="row" spacing={2} pt={2}>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: "secondary.main", color: "white" }}
                                onClick={() => actions.handleAccept(notification)}
                            >
                                {t("pending.accept")}
                            </Button>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: "primary.main", color: "white" }}
                                onClick={() => actions.handleReject(notification)}
                            >
                                {t("pending.reject")}
                            </Button>
                        </Stack>
                    </>
                );
            case 'transaction rejected':
                return (
                    <>
                        <AvatarUI sender={notification?.sender} />
                        <Box direction="row" sx={{ alignItems: "center", backgroundColor: "transparent !important" }}>
                            <Link to="/transactions" style={linkStyle}>
                                {notification.title}
                            </Link>
                        </Box>
                    </>
                );
            case 'message':
                return (
                    <>
                        <AvatarUI sender={notification?.sender} />
                        <Link
                            to="/chat"
                            style={{ fontSize: "16px", marginTop: "6px", color: theme.palette.secondary.main, textDecoration: "none", fontWeight: "400" }}
                        >
                            {notification.title}
                        </Link>
                    </>
                );
            case 'product available':
                return (
                    <>
                        <Typography variant="body1" sx={{ fontSize: "14px", mt: 1 }}>
                            {notification.title}
                        </Typography>
                        <Button variant="contained" sx={{ backgroundColor: "primary.main", color: "white", mt: 1 }} onClick={() => handleNavigation(`/product-detail/${notification?.objectId}`)} >
                            {t("watchlists.viewDetails")}
                        </Button>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Stack key={notification._id} sx={{ backgroundColor: "white", borderRadius: "5px", p: 2, position: "relative" }}>
            <Box
                sx={{ position: "absolute", top: "5px", right: "5px", cursor: "pointer", backgroundColor: "transparent !important" }}
                onClick={setAsRead}
            >
                <Tooltip title={t("setAsRead")}>
                    <VerifiedIcon sx={{ color: "primary.main" }} />
                </Tooltip>
            </Box>
            {renderContent()}
        </Stack>
    );
};

export default NotificationItem;
