import { Box, Typography } from '@mui/material';

const Section = ({ title, content, children,id }) => {
  return (
    <Box sx={{ mb: 4 }} id={id} name={id}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {title}
      </Typography>
      {content && (
        <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
          {content}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default Section;
