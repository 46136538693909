import { Container, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BaseForm from "../../../formik/BaseForm";
import { useVerifyAcountValidation } from "../../../validation/verifyAcount";
import CustomFormikField from "../../../formik/CustomFormikField";
import SubmitButton from "../../../formik/SubmitButton";
import { useVerifyAcountSubmit } from "../hooks/verifyAcountSubmit";
import ResendToken from "./ResendToken";
export const VerifyAcount = () => {
    const { verifyAcountValidationSchema } = useVerifyAcountValidation();
    const { t } = useTranslation("verifyAcount");
    const { method } = useParams();
    const { sendVerificationCode, loading } = useVerifyAcountSubmit(method);
    const initialValues = {
        code: "",
    };

    return (
        <Container maxWidth="sm" sx={{ py: 8 }}>
            <Typography variant="h4" sx={{ textAlign: "center", mb: 5, color: "secondary.main", fontWeight: "bold" }}>
                {t("title")}
            </Typography>

            <BaseForm
                initialValues={initialValues}
                validationSchema={verifyAcountValidationSchema}
                onSubmit={sendVerificationCode}
            >
                <Stack spacing={3} sx={{ width: "100%", backgroundColor: "secondary.main", padding: "15px", borderRadius: "10px" }}>
                    <CustomFormikField
                        name="code"
                        placeholder={method === "email" ? t("enterCode.email") : t("enterCode.phone")}
                        type="text"
                    />
                    <SubmitButton disabled={loading}>{t("verifyBtn")}</SubmitButton>
                </Stack>
            </BaseForm>

            <ResendToken />

        </Container>
    );
};

