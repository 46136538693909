import { Box, Typography } from '@mui/material';
import ListSection from './ListSection';

const TermsSection = ({ section,id }) => {
  return (
    <Box sx={{ mb: 3 }} id={id} name={id}>
      {section.title && (
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
          {section.title}
        </Typography>
      )}
      {section.content && (
        <Typography variant="body1" sx={{ mb: 1 }}>
          {section.content}
        </Typography>
      )}
      {section.items && <ListSection items={section.items} />}
      {section.subsections && (
        <Box sx={{ pl: 2, mt: 2 }}>
          {Object.entries(section.subsections).map(([key, subsection]) => (
            <TermsSection key={key} section={subsection} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default TermsSection;
