import { Button, Stack, Typography } from '@mui/material';
import useSendTokenToContact from '../hooks/SendToken';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const ResendToken = () => {
    const { sendTokenToEmail, sendTokenToPhoneNumber } = useSendTokenToContact();
    const { method, identifier } = useParams();
    const { t } = useTranslation("verifyAcount");
    const handleResendToken = () => {
        if (method === "email") {
            sendTokenToEmail(identifier);
        } else {
            sendTokenToPhoneNumber(identifier);
        }
    }
    return (
        <Stack direction="row"  alignItems="center" sx={{ mt: 2 }}>
            <Typography variant='body2'>{t("resendToken.title")}</Typography>
            <Button onClick={handleResendToken}>{t("resendToken.btn")}</Button>
        </Stack>
    )
}

export default ResendToken